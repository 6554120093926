import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from 'components/ProtectedRoute'
import Loader from 'components/Loader'
import { CurrentMarketplaceContextProvider } from 'context/CurrentMarketplace'
import { CurrentSellerContextProvider } from 'context/CurrentSeller'
import { CurrentFeeMarketplaceProvider } from 'views/FeeAnalysis/context/CurrentFeeMarketplaceContext'
import LandingPage from 'views/LandingPage'

const LazyDashboard = lazy(() => import('views/Dashboard'))
const LazySideBarWithHeader = lazy(() => import('views/SidebarWithHeader'))
const LazyAudits = lazy(() => import('views/FBAAudit'))
const LazySalesVisualization = lazy(() => import('views/SalesVisualization'))
const LazySalesVisualizationWalmart = lazy(() => import('views/SalesVisualizationWalmart'))

const LazySettings = lazy(() => import('views/Settings'))
const LazyFeeAnalysis = lazy(() => import('views/FeeAnalysis'))
const LazyLearn = lazy(() => import('views/Learn'))
const LazySellerAuthRedirect = lazy(() => import('views/SellerAuthRedirect'))
const LazyPageNotFound = lazy(() => import('views/PageNotFound'))
const LazyMarketplaceSelection = lazy(() => import('views/Settings/AccountSettings/MarketplaceSelection'))
const LazyAmazonSellerConnect = lazy(
	() => import('views/Settings/AccountSettings/MarketplaceSelection/AmazonSellerConnect')
)
const LazyWalmartSellerConnect = lazy(
	() => import('views/Settings/AccountSettings/MarketplaceSelection/WalmartSellerConnect')
)
const LazyWalmart3pAudits = lazy(() => import('views/Walmart3pAudits'))
const LazyWalmartDashboard = lazy(() => import('views/Walmart3pDashboard'))
const lazyAmazon1pAudits = lazy(() => import('views/Amazon1pAudits'))

const ProtectedRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<CurrentMarketplaceContextProvider>
				<CurrentSellerContextProvider>
					<CurrentFeeMarketplaceProvider>
						<LazySideBarWithHeader>
							<Suspense fallback={<Loader />}>
								<Routes>
									<Route path='/' element={<ProtectedRoute component={LandingPage} tag={'landing_page'} />} />
									<Route
										path='/amazon/seller_central'
										element={<ProtectedRoute component={LazyDashboard} tag={'dashboard'} />}
									/>
									<Route
										path='/amazon/seller_central/fba_audit/:tab?'
										element={<ProtectedRoute component={LazyAudits} tag={'fba_audit'} />}
									/>
									<Route
										path='/amazon/seller_central/sales_visualization'
										element={<ProtectedRoute component={LazySalesVisualization} tag={'sales_visualization'} />}
									/>
									<Route
										path='/settings/:tab?'
										element={<ProtectedRoute component={LazySettings} tag={'settings'} />}
									/>
									<Route
										path='/amazon/seller_central/fee_analysis/:tab?'
										element={<ProtectedRoute component={LazyFeeAnalysis} tag={'fee_analysis'} />}
									/>
									<Route path='/learn' element={<ProtectedRoute component={LazyLearn} tag={'learn'} />} />
									<Route
										path='/amazon/seller_central/seller_authentication_redirect'
										element={
											<ProtectedRoute component={LazySellerAuthRedirect} tag={'seller_authentication_redirect'} />
										}
									/>
									<Route
										path='/amazon/vendor_central'
										element={<ProtectedRoute component={lazyAmazon1pAudits} tag={'coming_soon'} />}
									/>
									<Route
										path='/walmart/seller_central'
										element={<ProtectedRoute component={LazyWalmartDashboard} tag={'coming_soon'} />}
									/>
									<Route
										path='/walmart/seller_central/audits'
										element={<ProtectedRoute component={LazyWalmart3pAudits} tag='walmart_3p_audits' />}
									/>
									<Route
										path='/walmart/seller_central/sales_visualization'
										element={<ProtectedRoute component={LazySalesVisualizationWalmart} tag={'sales_visualization'} />}
									/>

									<Route
										path='/marketplace_selection'
										element={<ProtectedRoute component={LazyMarketplaceSelection} tag={'marketplace_selection'} />}
									/>
									<Route
										path='/marketplace_selection/amazon_seller_connect'
										element={
											<ProtectedRoute component={LazyAmazonSellerConnect} tag={'amazon_marketplace_selection'} />
										}
									/>
									<Route
										path='/marketplace_selection/walmart_seller_connect'
										element={
											<ProtectedRoute component={LazyWalmartSellerConnect} tag={'amazon_marketplace_selection'} />
										}
									/>

									<Route path='*' element={<ProtectedRoute component={LazyPageNotFound} tag={'page_not_found'} />} />
								</Routes>
							</Suspense>
						</LazySideBarWithHeader>
					</CurrentFeeMarketplaceProvider>
				</CurrentSellerContextProvider>
			</CurrentMarketplaceContextProvider>
		</Suspense>
	)
}

export default ProtectedRoutes
