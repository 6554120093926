import { useAmazon3pSellerData, useWalmartSellers } from 'hooks/sellers'
import _ from 'lodash'
import { createContext, useContext, useEffect, useState } from 'react'
import { CurrentMarketplaceContext } from './CurrentMarketplace'
import { useVendorData } from 'hooks/vendors'

type ContextProps = {
	currentAmazon3pSeller: any
	setCurrentAmazon3pSeller: (seller: any) => void
	currentWalmart3pSeller: any
	setCurrentWalmart3pSeller: (seller: any) => void
	currentAmazon1pVendor: any
	setCurrentAmazon1pVendor: (vendor: any) => void
}
export const CurrentSellerContext = createContext({} as ContextProps)

type props = {
	children: JSX.Element | JSX.Element[]
}

export const CurrentSellerContextProvider = ({ children }: props) => {
	const { data: amazon3pSellers } = useAmazon3pSellerData()
	const { data: walmart3pSellers } = useWalmartSellers()
	const { data: amazon1pVendors } = useVendorData()
	const { currentMarketplace } = useContext(CurrentMarketplaceContext)
	const [currentAmazon3pSeller, setCurrentAmazon3pSeller] = useState<any>(null)
	const [currentWalmart3pSeller, setCurrentWalmart3pSeller] = useState<any>(null)
	const [currentAmazon1pVendor, setCurrentAmazon1pVendor] = useState<any>(null)

	useEffect(() => {
		if (!currentMarketplace) {
			setCurrentAmazon3pSeller(null)
			setCurrentWalmart3pSeller(null)
			setCurrentAmazon1pVendor(null)
			return
		}

		switch (currentMarketplace.id) {
			// Amazon Seller Central
			case 1:
				if (!amazon3pSellers) return
				if (currentAmazon3pSeller) {
					const foundSeller = amazon3pSellers.find((seller: any) => seller.Id === currentAmazon3pSeller.Id)
					if (foundSeller && foundSeller.Id !== currentAmazon3pSeller.Id) {
						setCurrentAmazon3pSeller(foundSeller)
						return
					}
				}
				setCurrentAmazon3pSeller(_.first(amazon3pSellers))
				break
			// Amazon Vendor Central
			case 2:
				if (!amazon1pVendors) return
				if (currentAmazon1pVendor) {
					const foundVendor = amazon1pVendors.find((vendor: any) => vendor.Id === currentAmazon1pVendor.Id)
					if (foundVendor && foundVendor.Id !== currentAmazon1pVendor.Id) {
						setCurrentAmazon1pVendor(foundVendor)
						return
					}
				}
				setCurrentAmazon1pVendor(_.first(amazon1pVendors))
				break
			// Walmart Seller Central
			case 3:
				if (!walmart3pSellers) return
				if (currentWalmart3pSeller) {
					const foundSeller = walmart3pSellers.find((seller: any) => seller.Id === currentWalmart3pSeller.Id)
					if (foundSeller && foundSeller.Id !== currentWalmart3pSeller.Id) {
						setCurrentWalmart3pSeller(foundSeller)
						return
					}
				}
				setCurrentWalmart3pSeller(_.first(walmart3pSellers))
				break

			default:
				break
		}
		// We need the eslint disable because set state is async
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amazon3pSellers, walmart3pSellers, currentMarketplace, amazon1pVendors])

	const currentSellerContext = {
		currentAmazon3pSeller,
		setCurrentAmazon3pSeller,
		currentWalmart3pSeller,
		setCurrentWalmart3pSeller,
		currentAmazon1pVendor,
		setCurrentAmazon1pVendor,
	}
	return <CurrentSellerContext.Provider value={currentSellerContext}>{children}</CurrentSellerContext.Provider>
}

export const { Consumer: UserContextConsumer } = CurrentSellerContext
