import { useQuery } from '@tanstack/react-query'
import { fetcher } from 'api/fetchers'

export function useVendorData() {
	const queryKey = ['/vendor']
	const { isLoading, error, data } = useQuery({
		queryKey: queryKey,
		queryFn: () => {
			const [url] = queryKey
			return fetcher(url)
		},
	})

	return {
		data,
		isLoading,
		error,
	}
}
