import { useAmazon3pSellerData, useWalmartSellers } from 'hooks/sellers'
import { useVendorData } from 'hooks/vendors'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type ContextProps = {
	currentMarketplace: any
	setCurrentMarketplace: (seller: any) => void
	marketplaces: {
		name: string
		logoUrl: string
		id: number
	}[]
	marketplaceSellers: any[]
}

export const CurrentMarketplaceContext = createContext({} as ContextProps)

type props = {
	children: JSX.Element | JSX.Element[]
}

/* 
	So, I am not pulling this back from the db because if we are going to add a new marketplace, we are going to have
	to do a bunch of UI work anyway. The way I have it set up, it's also kind of a UI construct anyway
*/
export const allMarketplaces = [
	{
		name: 'Seller Central',
		logoUrl: 'https://mihostedimages.s3.amazonaws.com/amazon_icon.png',
		id: 1,
		retailer: 'Amazon',
		url: '/amazon/seller_central',
	},
	{
		name: 'Vendor Central',
		logoUrl: 'https://mihostedimages.s3.amazonaws.com/amazon_icon.png',
		id: 2,
		retailer: 'Amazon',
		url: '/amazon/vendor_central',
	},
	{
		name: 'Seller Center',
		logoUrl: 'https://mihostedimages.s3.amazonaws.com/walmart_icon.png',
		id: 3,
		retailer: 'Walmart',
		url: '/walmart/seller_central',
	},
]

const localStorageKey = 'valence-current-marketplace'

function getValueFromStorage() {
	const savedMarketplace = localStorage.getItem(localStorageKey)
	return savedMarketplace ? allMarketplaces.find((m) => parseInt(savedMarketplace) === m.id) : null
}

export const CurrentMarketplaceContextProvider = ({ children }: props) => {
	const [currentMarketplace, setCurrentMarketplace] = useState<any>(getValueFromStorage())
	const navigate = useNavigate()

	const { data: amazon1pSellers, isLoading: isLoadingAmazon1p } = useVendorData()
	const { data: walmartSellers, isLoading: isLoadingWalmart } = useWalmartSellers()
	const { data: amazon3pSellers, isLoading: isLoadingAmazon3p } = useAmazon3pSellerData()

	const marketplaces = useMemo(() => {
		// Everyone gets amazon 3p
		const marketplacesArray = [allMarketplaces[0]]

		if (amazon1pSellers?.length) {
			marketplacesArray.push(allMarketplaces[1])
		}

		if (walmartSellers?.length) {
			marketplacesArray.push(allMarketplaces[2])
		}

		return marketplacesArray
	}, [amazon1pSellers, walmartSellers])

	useEffect(() => {
		if (!marketplaces || !currentMarketplace) return
		localStorage.setItem(localStorageKey, currentMarketplace.id)
	}, [currentMarketplace, marketplaces])

	useEffect(() => {
		if (isLoadingAmazon1p || isLoadingAmazon3p || isLoadingWalmart) return

		// This handles a weird state we can get in as developers, since we jump between accounts
		if (!marketplaces.some((m) => m.id === currentMarketplace?.id)) {
			setCurrentMarketplace(marketplaces[0])
			navigate(marketplaces[0].url)
			return
		}

		if (!currentMarketplace && marketplaces.length) {
			setCurrentMarketplace(marketplaces[0])
			return
		}
	}, [currentMarketplace, isLoadingAmazon1p, isLoadingAmazon3p, isLoadingWalmart, marketplaces, navigate])

	const marketplaceSellers = useMemo(() => {
		switch (currentMarketplace?.id) {
			case 1:
				return amazon3pSellers || []
			case 2:
				return amazon1pSellers || []
			case 3:
				return walmartSellers || []
			default:
				return []
		}
	}, [currentMarketplace?.id, amazon3pSellers, amazon1pSellers, walmartSellers])

	const currentMarketplaceContext = {
		currentMarketplace,
		setCurrentMarketplace,
		marketplaces,
		marketplaceSellers,
	}

	return (
		<CurrentMarketplaceContext.Provider value={currentMarketplaceContext}>
			{children}
		</CurrentMarketplaceContext.Provider>
	)
}

export const { Consumer: CurrentMarketplaceConsumer } = CurrentMarketplaceContext
